import * as React from "react";
import "./styles.css";

const allergies = new Set([
  "pomme",
  "poire",
  "peche",
  "abricot",
  "crabe",
  "noix",
  "cacahuètes"
]);
const name = "Antoine";
export default function App() {
  const [search, _setSearch] = React.useState("");

  const [isAllergic, setIsAllergic] = React.useState<undefined | boolean>(
    undefined
  );

  const setSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAllergic(undefined);
    _setSearch(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (allergies.has(search)) {
      setIsAllergic(true);
    } else {
      setIsAllergic(false);
    }
    event.preventDefault();
  };

  return (
    <div className="App">
      <form onSubmit={handleSubmit}>
        <div style={{ display: "flex" }}>
          <h1>
            {`Est-ce qu'${name} peut manger de`}
            <input type="text" value={search} onChange={setSearch} />
          </h1>
        </div>
      </form>
      <Checker isAllergic={isAllergic} />
    </div>
  );
}

function Checker({ isAllergic }: { isAllergic?: boolean }) {
  if (isAllergic === undefined) return null;
  if (isAllergic) return <h1>Non 😭</h1>;
  return <h1>Oui 🤸‍♀️</h1>;
}
